.scroll-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    background-color: #6DAC4FFF;
}

.scroll-text {
    display: inline-block;
    animation: scroll 20s linear infinite;
    padding:16px 0% 16px 100%;
    
  
}

@keyframes scroll {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}