@font-face {
  font-family: 'VFuturaMedium';
  src: url('./fonts/VFuturaMedium.woff2') format('woff2'),
       url('./fonts/VFuturaMedium.woff') format('woff'),
       url('./fonts/VFuturaMedium.TTF') format('TTF');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'VFuturaMedium', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  overflow-x: hidden ;
}

.style-navcontent { 
  height: 20px;
  width: 100%;
}


.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
  background-color :  white ; 
  border-radius: 12px;
}


.input-search{
  height: 24px;
  width: 300px;
  border-style: none;
  padding: 10px;
  font-size: 16px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 12px;
  transition: all .5s ease-in-out;
  background-color: black;
  padding-right: 40px;
  color:#ff6110 ;
}

.input-search::placeholder{
  color:white;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 100;
}

.input-search:focus::placeholder{
  color:#ff6110;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 100;
}

.input-search:focus{
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #ff6110;
}

.search-icon { 
  position: absolute;
  right: 20px;
  top: 10px;
}

.orange {
  color:#ff6110;
}

.white { 
  color: white;
}

.premium-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust as needed */
  overflow: hidden;
  margin-left: 62px;
  margin-top: 64px;
  top: 10%;
}

.text-container {
  position: relative;
  height: 100%; /* Adjust as needed */
  overflow: hidden;
  margin-left: 162px;
  margin-top: 64px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.image-fade {
  position: absolute;
  height: 100%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.image-fade.visible {
  opacity: 1;
}

.shop-now-right-arrow { 
  margin-left: 12px;
}

/* what we offer cards */
* {
  box-sizing: border-box;
}

.card {
  position: sticky;
  top: 100px;
}

.card__inner {
  will-change: transform;
  background: white;
  border-radius: 14px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px hsla(265.3, 20%, 10%, 35%);
  transform-origin: center top;
}

.cards {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(var(--cards-count), var(--card-height));
  gap: 40px 0;
}

.card__image-container {
  display: flex;
  width: 40%;
  flex-shrink: 0;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.card__content {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.orange_verified_container { 
  height: 140px;
  width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.orange_verified_container img { 
  height: 136%;
  width: 100%;
  flex:1;
  position: relative;
  bottom: 30px;
}

.card__title {
  padding: 0;
  margin: 0;
  font-size: 60px;
  font-weight: 600;
  color: #16263a;
}


.card__description {
  line-height: 1.4;
  font-size: 24px;
  color: #16263a;
}

.each_list_container { 
  display: flex;
  gap: 12px;
  margin: 6px;
}

.space {
  height: 20vh;
}

.space--small {
  height: 12vh;
}

@media (max-width: 768px) {
  .card__inner {
    flex-direction: column;
  }

  .space {
    height: 0;
  }

  .space--small {
    height: 4vh;
  }

  .card { 
    width: 90%;
  }
  .card__image-container {
    width: 100%;
  }

  .card__image {
    aspect-ratio: 16 / 9;
  }

  .card__title {
    font-size: 32px;
  }

  .card__description {
    font-size: 16px;
  }

  .card__content {
    padding: 30px 20px;
  }

  .cards {
    max-width: initial;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
}



/* Styles Checkbox*/

.checkboxes__row {
  display: flex;
}
.checkboxes__item {
  padding: 15px;
}


.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
.checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}
.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox.style-c .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
}



.single-img {
  position: relative;
  width: 30%;
  float: left;
  margin: 0 1% 10%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.single-img:hover .img-overlay {
  height: 40%;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: poppins;
  letter-spacing: 2px;
  font-weight: bold;
}
.text span{
  font-weight: 300;
}

@media screen and (max-width: 768px) {
.orange_verified_container { 
  width: 100%;
  height:50px;
}

.orange_verified_container img { 
  height: 150%;
  width: 100%;
  flex:1;
  position: relative;
  bottom: 24px;
}
}