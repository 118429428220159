.pagination button {
    margin: 0 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .pagination .active {
    font-weight: bold;
    background-color: #4caf50;
    color: white;
  }
  